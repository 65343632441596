import { ReactElement } from 'react'
import styles from './styles.module.scss'
import { SearchResultContent } from '@shared/interfaces/search.interface'
import { Img } from '@library/_images/img'
import ChannelLogo from '@ui/channel/logo'

export default function ContentCard({
  contentId,
  contentTitle,
  contentThumbnail,
  channelName,
  channelLogo,
  channelUrl,
  contentType,
}: SearchResultContent): ReactElement {
  return (
    <article className={styles.contentCard}>
      <div className={styles.image}>
        <Img alt={contentTitle} src={contentThumbnail} aspectRatio={'16/9'} />
      </div>
      <div className={styles.title}>
        <ChannelLogo avatar={channelLogo} />
        <span>{contentTitle}</span>
      </div>
    </article>
  )
}
